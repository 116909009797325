import React from 'react';
import { Link } from "gatsby"
import { container, navContainer, link, active } from '../styles/nav.module.css';

const ListLink = props => (
  <li>
    <Link activeClassName={active} className={link} to={props.to}>{props.children}</Link>
  </li>
);

export default function Nav (props) {
  return (
    <div className={container}>
      <nav className={navContainer}>
        <ul>
          <ListLink exact to='/'>{'WELCOME'}</ListLink>
          <ListLink to='/menu'>{'MENU'}</ListLink>
          <ListLink to='/locations'>{'LOCATIONS'}</ListLink>
          <ListLink to='/order'>{'ORDER'}</ListLink>
          <ListLink to='/contact'>{'CONTACT US'}</ListLink>
        </ul>
      </nav>
    </div>
  );
}
