import React from "react";
import Nav from "./nav";
import Footer from "./footer";
import { CSSTransitionGroup } from 'react-transition-group';
import "./animations.css"

export default ({ children }) => (
  <div>
    <Nav/>
    <CSSTransitionGroup
      transitionName="fade"
      transitionAppear={true}
      transitionAppearTimeout={1000}
      transitionEnter={true}
      transitionEnterTimeout={1000}
      transitionLeave={true}
      transitionLeaveTimeout={1000}>
          {children}
      </CSSTransitionGroup>
    <Footer/>
  </div>
)
