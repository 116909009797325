import React, { Component } from 'react';
import {
  wrap, homePage, overlay, centeredContainer, logo, subHead, info, infoLink,
  divideHr, desc
} from "../styles/home.module.css";
import Layout from "../components/layout";
import logoImg from "../images/logo.png"

export default class Home extends Component {
  render () {
    return (
      <Layout>
        <span className={wrap} key={"home"}>
          <div className={homePage}>
            <div className={overlay}>
              <div className={centeredContainer}>
                <div className={info}>
                  <img className={logo} src={logoImg} alt="La Bohemia Logo"/>
                </div>
                <p className={subHead}>{'European Artisan Bakery'}</p>
                <div className={info}>
                  <a className={infoLink} target='_tab' href="https://www.google.com/maps/place/La+Bohemia+Bakery/@39.0579941,-77.114542,17z/data=!3m1!4b1!4m5!3m4!1s0x89b7cc2d37b3812f:0x1d1978c156ca652b!8m2!3d39.05799!4d-77.112348">
                    5540 Wilkins Court, Rockville, MD, 20852
                  </a>
                  <a className={infoLink} href="tel:+1-240-360-3697">(240)-360-3697</a>
                  <a className={infoLink} target='_tab' href="mailto:labohemia@lbbakery.com">
                    labohemia@lbbakery.com
                  </a>
                  <hr  className={divideHr} />
                  <p  className={desc}>{'We are a European artisan bakery located in Maryland. Our priority is to bring you authentic quality European baked goods at an affordable price.'}</p>
                </div>
              </div>
            </div>
          </div>
        </span>
      </Layout>
    );
  }
}
